import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import ProductListPage from '~/components/Product/ListPage'

const colorList = {
  'mac-and-cheese': 'cheddy-mac',
  pasta: 'PCP',
  gifts: 'vegan-is-believin',
  goods: 'here-comes-truffle',
  'variety-packs': 'mover-shaker',
  'dietary-preferences': 'smokey-dokey',
}

const Shop = ({ data }) => {
  const [currentCollectionSlug, setCurrentCollectionSlug] = useState()   
  if (!data || !data.catalog) {
    return null
  }
  const { page, catalog } = data
  const filteredCatalogCollections = catalog?.collections?.filter(
    collection => !collection.hidden,
  )
  catalog.collections = filteredCatalogCollections
  return (
    <Layout theme={colorList[`${currentCollectionSlug}`] ?? 'hey-hey-elote'}>
      <ProductListPage page={page} catalog={catalog} colorList={colorList} currentCollectionSlug={currentCollectionSlug} setCurrentCollectionSlug={setCurrentCollectionSlug}/>
    </Layout>
  )
}

export default Shop

export const query = graphql`
  query ShopPage($locale: String) {
    catalog: contentfulCatalog(
      slug: { eq: "catalog" }
      node_locale: { eq: $locale }
    ) {
      collections {
        ...CollectionDetailsFragment
      }
    }
    page: contentfulPage(
      slug: { eq: "shop-all" }
      node_locale: { eq: $locale }
    ) {
      name
      ...ContentHeroFragment
    }
  }
`
